import { PrivateKey } from "@hiveio/dhive";
import { getCookie } from "./cookie";
import { isSSR } from "./ssr";

const IMAGE_HOSTER = "https://img.inleo.io";
const PREFIX = "ImageSigningChallenge";

const _appendBuffer = function (buffer1, buffer2) {
  const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp.buffer;
};

// https://developers.hive.io/services/imageHoster.html
export const uploadImage = async (
  image: File,
  account: any,
  opt_auth?: { proxy: string; posting_key: string }
) => {
  return new Promise(async (resolve, reject) => {
    //console.log({ account });

    let proxy;

    const { activeAccount, keys } = account;
    //const { _signature } = keys;

    if (opt_auth) {
      proxy = opt_auth.proxy;
    } else {
      const { proxy: proxy_from_session } = getCookie("__session");
      proxy = proxy_from_session;
    }
    //console.log({ proxy });
    //const keychainLogin = proxy === "keychain";
    //const hivesignerLogin = ["hivesigner", "metamask"].includes(proxy);
    if (!activeAccount?.name) return console.error("no key");
    if (!isSSR()) window.Buffer = window.Buffer || require("buffer").Buffer;

    const file = image;
    const reader = new FileReader();

    // Read the file as ArrayBuffer
    reader.readAsArrayBuffer(file);
    const data = await new Promise(resolve => {
      reader.onload = function () {
        const prefixString = new Blob([PREFIX], {
          type: "text/plain"
        }).arrayBuffer();
        // @ts-ignore
        const binaryString = new Blob([new Uint8Array(reader.result)], {
          type: file.type
        }).arrayBuffer();

        Promise.all([prefixString, binaryString]).then(([prefix, binary]) => {
          return resolve(_appendBuffer(prefix, binary));
        });
      };
    });

    if (proxy == "hivesigner" || file.size > 100) {
      const formData = new FormData();

      formData.append("fileField", file, file.name);
      const response = await fetch(
        `${IMAGE_HOSTER}/hs/${"eyJzaWduZWRfbWVzc2FnZSI6eyJ0eXBlIjoicG9zdGluZyIsImFwcCI6Imxlb2ZpbmFuY2UifSwiYXV0aG9ycyI6WyJyYW5kb21hY2NvdW50MSJdLCJ0aW1lc3RhbXAiOjE3MTU3MjI3MjMsInNpZ25hdHVyZXMiOlsiMjAzNzhhZGU3NWI3NDM2MzExNTJjYTdhZTgyYjQ2N2E4NjYzZTc5MzZlYjQyMjI0ZjJjOGFiNjczMjNiMDFkZGZhNzMxMjA2NDVkMGQ5Y2QzNmZlZTFmZTMzYzBiMTFlN2Y3NjM2ODIxNzIzNDRlY2NiOTFhZWQ4MDkzNGQ1ODY4NSJdfQ.."}`,
        {
          method: "POST",
          body: formData // Pass the FormData object as the body
          // Do not set Content-Type header. The browser will set it with the correct boundary.
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // You can process the response if needed
      const result = await response.json(); // Assuming JSON response
      return resolve({ url: result?.url?.replaceAll(".JPG", ".jpg") });
    } else if (proxy === "leolock") {
      reader.readAsArrayBuffer(file);
      if (data) {
        let posting_key = "";

        if (opt_auth) {
          posting_key = opt_auth.posting_key;
        } else {
          const { auth } = getCookie("__session");
          const { posting_key: posting_key_from_auth } = JSON.parse(auth);

          posting_key = posting_key_from_auth;
        }

        const hashBuffer = await crypto.subtle.digest("SHA-256", data);
        const signature = PrivateKey?.fromString(posting_key).sign(
          Buffer.from(hashBuffer)
        );

        const formData = new FormData();
        formData.append("file", file);

        const response = await fetch(
          `${IMAGE_HOSTER}/${activeAccount.name}/${signature}`,
          {
            method: "POST",
            body: formData
          }
        );

        const result = await response.json();
        resolve({ url: result?.url?.replaceAll(".JPG", ".jpg") });
      }
    } else {
      reader.readAsArrayBuffer(file);

      let signature;

      if (data) {
        (window as any).hive_keychain.requestSignBuffer(
          activeAccount.name,
          JSON.stringify({
            type: "Buffer",
            data: Array.from(new Int8Array(data))
          }),
          "Posting",
          async (signature: any) => {
            try {
              console.log({ signature });

              const formData = new FormData();
              formData.append("file", file);

              const response = await fetch(
                `${IMAGE_HOSTER}/${activeAccount.name}/${signature.result}`,
                {
                  method: "POST",
                  body: formData
                }
              );

              if (response.ok) {
                const res = await response.json();
                const { error, url } = res;

                if (error) {
                  console.error("upload_error", error);
                  throw new Error(error);
                }

                resolve({ url: url?.replaceAll(".JPG", ".jpg") });
                return url;
              } else {
                // throw new Error(`HTTP error! status: ${response.status}`);
                console.log("upload error on else", response);

                reject();
              }
            } catch (e) {
              console.error("upload_error", e);
            }
          },
          null
        );
      }
    }
    // Read the file as ArrayBuffer
  });
};
